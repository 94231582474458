<template>
    <div class="c-price-details-table">
        <recess-divider variant="large" show-line />

        <div class="row">
            <div class="col-12">
                <h2 class="qa-order-details-price-title d-flex">{{ PROVIDER.Order.OrderDetails.PriceDetailsTable.Title
                    }}</h2>
                <span class="u-fw-semi-bold qa-order-is-vat-exempt" v-if="priceInfo.isVatExempt">{{
                    PROVIDER.Order.OrderDetails.PriceDetailsTable.VatExemption }}</span>
            </div>
        </div>

        <div v-if="priceInfo.details">
            <div class="row">
                <div class="col-12">
                    <recess-card>
                        <recess-table-flex class="qa-table-order-details-price-list u-overflow-inherit"
                            :table-options="setTableOptions" :pagination-options="setPaginationOptions">
                            <recess-table-flex-head>
                                <recess-table-flex-row>
                                    <recess-table-flex-head-item v-for="header in headers" :key="header.key"
                                        :cell-size="header.cellSize" :class="`qa-price-details-header-${header.key}`">
                                        {{ header.title }}
                                    </recess-table-flex-head-item>
                                </recess-table-flex-row>
                            </recess-table-flex-head>

                            <recess-table-flex-body>
                                <recess-table-flex-row v-for="(priceDetail, index) in priceInfo.details" :key="index"
                                    class="align-items-center">
                                    <recess-table-flex-cell :class="`qa-price-details-list-cost-type-${index}`"
                                        cell-size="medium">
                                        <span class="u-fw-semi-bold">{{
                                            priceDetail.costTypeDisplayValue
                                        }}</span>
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell :class="`qa-price-details-list-quantity-${index}`"
                                        :data-label="headers[1].title">
                                        {{ priceDetail.quantity }}
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell
                                        :class="`qa-price-details-list-subtotal-withoutVAT-${index}`"
                                        :data-label="headers[2].title" cell-size="small" class="text-right">
                                        {{ priceDetail.subtotalWithoutVAT | currency }}
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell :class="`qa-price-details-list-discount-amount-${index}`"
                                        :data-label="headers[3].title" class="text-right">
                                        {{ priceDetail.discountAmount | currency }}
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell :class="`qa-price-details-list-total-withVAT-${index}`"
                                        :data-label="headers[4].title" class="text-right" cell-size="small">
                                        {{ priceDetail.total | currency }}
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell :class="`qa-price-details-list-vat-amount-${index}`"
                                        :data-label="headers[5].title" class="text-right">
                                        {{ priceDetail.vatAmount | currency }}
                                    </recess-table-flex-cell>
                                </recess-table-flex-row>

                                <recess-table-flex-row class="align-items-center">
                                    <recess-table-flex-cell cell-size="medium">
                                        <h3 class="m-0 qa-price-details-total-label">{{
                                            PROVIDER.Order.OrderDetails.PriceDetailsTable.TableHeaders.TotalLabel }}
                                        </h3>
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell cell-size="small"></recess-table-flex-cell>

                                    <recess-table-flex-cell> </recess-table-flex-cell>

                                    <recess-table-flex-cell> </recess-table-flex-cell>

                                    <recess-table-flex-cell v-if="priceInfo.total" :data-label="headers[4].title"
                                        class="text-right qa-price-details-total-output" cell-size="small">
                                        {{ priceInfo.total | currency }}
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell v-else :data-label="headers[4].title"
                                        class="text-right qa-price-details-total-output" cell-size="small">
                                        {{ '-' }}
                                    </recess-table-flex-cell>

                                    <recess-table-flex-cell> </recess-table-flex-cell>
                                </recess-table-flex-row>
                            </recess-table-flex-body>
                        </recess-table-flex>
                    </recess-card>
                </div>
            </div>
        </div>
        <div v-else class="qa-order-no-price-details-text">{{ noResultsTextLabel }}</div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    props: {
        priceInfo: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            PROVIDER,
            headers: [
                { key: '', title: '', cellSize: 'medium' },
                {
                    key: 'Quantity',
                    title: PROVIDER.Order.OrderDetails.PriceDetailsTable.TableHeaders.Quantity
                },
                {
                    key: 'SubtotalWithoutVAT',
                    title: PROVIDER.Order.OrderDetails.PriceDetailsTable.TableHeaders.SubtotalWithoutVAT,
                    cellSize: 'small',
                    cssClass: 'd-flex align-items-center justify-content-end'
                },
                {
                    key: 'DiscountAmount',
                    title: PROVIDER.Order.OrderDetails.PriceDetailsTable.TableHeaders.DiscountAmount,
                    cssClass: 'd-flex align-items-center justify-content-end'
                },
                {
                    key: 'Total',
                    title: PROVIDER.Order.OrderDetails.PriceDetailsTable.TableHeaders.Total,
                    cellSize: 'small',
                    cssClass: 'd-flex align-items-center justify-content-end'
                },
                {
                    key: 'VatAmount',
                    title: PROVIDER.Order.OrderDetails.PriceDetailsTable.TableHeaders.VatAmount,
                    cssClass: 'd-flex align-items-center justify-content-end'
                }
            ],
            noResultsTextLabel: PROVIDER.Order.OrderDetails.PriceDetailsTable.NoResults,
            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                setCellSizes: true
            },
            setPaginationOptions: {
                showTopPagination: false,
                showBottomPagination: false
            }
        }
    }
}
</script>
